import React from "react";

export function TotalFormsSvg() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#FF7A00" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.0636 17.4386C21.7085 18.7922 21.7085 20.9737 21.7085 25.3335V37.6668C21.7085 42.0267 21.7085 44.2081 23.0636 45.5617C24.4172 46.9168 26.5987 46.9168 30.9585 46.9168H34.0418C38.4017 46.9168 40.5831 46.9168 41.9367 45.5617C43.2918 44.2081 43.2918 42.0267 43.2918 37.6668V25.3335C43.2918 20.9737 43.2918 18.7922 41.9367 17.4386C40.5831 16.0835 38.4017 16.0835 34.0418 16.0835H30.9585C26.5987 16.0835 24.4172 16.0835 23.0636 17.4386ZM26.7189 39.2085C26.7189 38.9018 26.8407 38.6077 27.0576 38.3909C27.2744 38.1741 27.5685 38.0522 27.8752 38.0522H32.5002C32.8068 38.0522 33.1009 38.1741 33.3178 38.3909C33.5346 38.6077 33.6564 38.9018 33.6564 39.2085C33.6564 39.5152 33.5346 39.8093 33.3178 40.0261C33.1009 40.2429 32.8068 40.3647 32.5002 40.3647H27.8752C27.5685 40.3647 27.2744 40.2429 27.0576 40.0261C26.8407 39.8093 26.7189 39.5152 26.7189 39.2085ZM27.8752 31.8856C27.5685 31.8856 27.2744 32.0074 27.0576 32.2242C26.8407 32.4411 26.7189 32.7352 26.7189 33.0418C26.7189 33.3485 26.8407 33.6426 27.0576 33.8594C27.2744 34.0763 27.5685 34.1981 27.8752 34.1981H37.1252C37.4318 34.1981 37.7259 34.0763 37.9428 33.8594C38.1596 33.6426 38.2814 33.3485 38.2814 33.0418C38.2814 32.7352 38.1596 32.4411 37.9428 32.2242C37.7259 32.0074 37.4318 31.8856 37.1252 31.8856H27.8752ZM26.7189 26.8752C26.7189 26.5685 26.8407 26.2744 27.0576 26.0576C27.2744 25.8407 27.5685 25.7189 27.8752 25.7189H37.1252C37.4318 25.7189 37.7259 25.8407 37.9428 26.0576C38.1596 26.2744 38.2814 26.5685 38.2814 26.8752C38.2814 27.1818 38.1596 27.4759 37.9428 27.6928C37.7259 27.9096 37.4318 28.0314 37.1252 28.0314H27.8752C27.5685 28.0314 27.2744 27.9096 27.0576 27.6928C26.8407 27.4759 26.7189 27.1818 26.7189 26.8752Z"
        fill="white"
      />
      <path
        opacity="0.5"
        d="M22.0708 19.2563C21.7085 20.6176 21.7085 22.5231 21.7085 25.3336V37.6669C21.7085 40.4774 21.7085 42.3813 22.0708 43.7442L21.7085 43.7164C20.2054 43.5684 19.194 43.2339 18.4386 42.4785C17.0835 41.1249 17.0835 38.9434 17.0835 34.5836V28.4169C17.0835 24.0556 17.0835 21.8756 18.4386 20.5221C19.194 19.7666 20.2054 19.4321 21.7085 19.2841L22.0708 19.2563ZM42.9295 43.7442C43.2918 42.3813 43.2918 40.4758 43.2918 37.6669V25.3336C43.2918 22.5231 43.2918 20.6176 42.9295 19.2563L43.2918 19.2841C44.795 19.4321 45.8063 19.7666 46.5617 20.5221C47.9168 21.8756 47.9168 24.054 47.9168 28.4154V34.5821C47.9168 38.945 47.9168 41.1233 46.5617 42.4785C45.8063 43.2339 44.795 43.5684 43.2918 43.7164L42.9295 43.7442Z"
        fill="white"
      />
    </svg>
  );
}
