import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Box,
  Typography,
  TextField,
  Modal,
  Autocomplete,
} from "@mui/material";
import { SolidButton } from "../../../../Components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useIsMobile } from "../../../../contexts/isMobile";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import {
  useAddNewCareAllyMutation,
  useUpdateCareAllyMutation,
} from "../../../../store/careAllysSlice";
import { formatPhone } from "../../../../utils";
import { toast } from "react-toastify";
import { LanguageData } from "../../patientUtils";
import { languages } from "../../utils";

export default function CareAllyModal({
  open,
  setOpen,
  fetchAgain,
  setEditData,
  editData,
  status,
}) {
  const { user } = useSelector((state) => state.user);
  const [language, setLanguage] = useState("");
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    relationship: "",
    phoneNumber: "",
  });
  const [isValidNumber, setIsValidNumber] = useState(null);
  const [addNewCareAlly, {isLoading: isAddCareAllyLoading}] = useAddNewCareAllyMutation();
  const [updateCareAlly, {isLoading: isEditCareAllyLoading}] = useUpdateCareAllyMutation();
  const isMobile = useIsMobile();
  const onChange = (e) => {
    setForm((form) => ({ ...form, [e.target.name]: e.target.value }));
  };

  const handleChange = (newValue) => {
    matchIsValidTel(newValue); // boolean
    setIsValidNumber(matchIsValidTel(newValue));
  };

  const resetAllValues = () => {
    setForm({
      firstName: "",
      lastName: "",
      relationship: "",
      phoneNumber: "",
    });
    setLanguage("");
    setIsValidNumber(null);
    setEditData({});
  };

  const { id } = useParams();
  const createCareAlly = async (e) => {
    if (status === "active") {
      try {
        // check if the any field is empty
        const emptyFields = Object.values(form).some((value) => value === "");

        if (emptyFields) {
          toast.error("Please filled all fields!");
          return;
        }

        const formattedPhone = formatPhone(form?.phoneNumber);
        const body = {
          ...form,
          phoneNumber: formattedPhone,
          language: language,
        };
        let response;
        if (!editData?._id) {
          response = await addNewCareAlly({ id, body });
        } else {
          response = await updateCareAlly({ id: editData?._id, body });
        }

        if (response?.data) {
          const responseToast = editData?._id
            ? "Care Ally Updated Successfully!"
            : "Care Ally Created Successfully!";
          toast.success(responseToast);
          fetchAgain();
          resetAllValues();
          setOpen(false);
        } else {
          toast.error(response.error?.data?.error || response.error?.data);
        }
      } catch (err) {
        console.log("Error:", err);
        toast.error("Something went wrong!");
      }
    } else {
      toast.error("The patient status is currently inactive.");
      resetAllValues();
      setOpen(false);
    }
  };

  const handleClose = () => {
    resetAllValues();
    setOpen(false);
  };

  useEffect(() => {
    if (editData) {
      setForm({
        firstName: editData?.firstName || "",
        lastName: editData?.lastName || "",
        relationship: editData?.relationship || "",
        phoneNumber: editData?.phoneNumber || "",
      });
      setLanguage(editData?.language || "");
      if (editData?.phoneNumber === 12) {
        setIsValidNumber(false);
      } else {
        setIsValidNumber(true);
      }
    }
  }, [editData]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "80%" : 700,
          height: isMobile ? 435 : 400,
          bgcolor: "#F5F5F5",
          p: 4,
          borderRadius: "10px",
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Care Ally
          </Typography>
          <Box sx={{ cursor: "pointer" }} onClick={handleClose}>
            <CloseIcon />
          </Box>
        </Stack>

        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography fontSize={12} color={"#979797"} mt={2}>
              First Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="firstName"
              value={form.firstName}
              onChange={onChange}
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography fontSize={12} color={"#979797"} mt={2}>
              Last Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="lastName"
              value={form.lastName}
              onChange={onChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography fontSize={12} color={"#979797"} mt={2}>
              Relationship <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              variant="standard"
              fullWidth
              name="relationship"
              value={form.relationship}
              onChange={onChange}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography fontSize={12} color={"#979797"} mt={1}>
              Phone No <span style={{ color: "red" }}>*</span>
            </Typography>
            <MuiTelInput
              required
              id="standard"
              variant="standard"
              fullWidth
              name="phoneNumber"
              defaultCountry="US"
              onlyCountries={["US"]}
              error={isValidNumber === false}
              helperText={isValidNumber === false && "Incorrect entry."}
              value={form.phoneNumber}
              onChange={(newValue) => {
                const event = {
                  target: { name: "phoneNumber", value: newValue },
                };
                onChange(event);
                handleChange(newValue);
              }}
              sx={{
                padding: "3px",
                "& .MuiInputLabel-root": {
                  padding: "3px",
                },
              }}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography fontSize={12} mt={2} color="#979797">
             Preferred Language <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              value={language}
              onChange={(event, newValue) => {
                setLanguage(newValue);
              }}
              options={languages?.map((item) => item.label)}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </Grid>
        </Grid>
        <Stack
          direction="row"
          justifyContent={"flex-end"}
          sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}
        >
          <SolidButton label={editData?._id?"Save":"Create"} onClick={createCareAlly} disabled={isAddCareAllyLoading || isEditCareAllyLoading} />
        </Stack>
      </Box>
    </Modal>
  );
}
