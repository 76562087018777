import React from "react";

export function TotalTasksSvg() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#EB00FF" />
      <g clip-path="url(#clip0_0_1)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M43.6 18.9333H47.3C48.2813 18.9333 49.2224 19.3232 49.9163 20.017C50.6102 20.7109 51 21.652 51 22.6333V47.3C51 48.2813 50.6102 49.2224 49.9163 49.9163C49.2224 50.6102 48.2813 51 47.3 51H17.7C16.7187 51 15.7776 50.6102 15.0837 49.9163C14.3898 49.2224 14 48.2813 14 47.3V22.6333C14 21.652 14.3898 20.7109 15.0837 20.017C15.7776 19.3232 16.7187 18.9333 17.7 18.9333H21.4V14H23.8667V18.9333H41.1333V14H43.6V18.9333ZM28.8 33.7333H21.4V31.2667H28.8V33.7333ZM43.6 31.2667H36.2V33.7333H43.6V31.2667ZM28.8 41.1333H21.4V38.6667H28.8V41.1333ZM36.2 41.1333H43.6V38.6667H36.2V41.1333Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_1">
          <rect
            width="37"
            height="37"
            fill="white"
            transform="translate(14 14)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
