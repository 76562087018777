import React from "react";
import QueueTabs from "./QueueTabs";
import { useIsMobile } from '../../contexts/isMobile'
import {Box} from '@mui/material'
import {InactivityCheck} from "../../Components/InactivityCheck"

export default function QueueIndex() {
  const isMobile=useIsMobile()
  return (
    <>
      <div style={{ padding: isMobile ? "1.5rem 1rem" : "1.5rem 2rem" }}>
        <Box sx={{ padding: isMobile ? "6px" : 2}}>
          <QueueTabs />
        </Box>
        <InactivityCheck />
      </div>
    </>
  );
}
