import React, { useState, useEffect, useRef } from 'react'
import { Drawer, Box } from '@mui/material'
import { useIsMobile } from '../../contexts/isMobile'
import { useParams } from 'react-router-dom'
import ScrollableFeed from 'react-scrollable-feed'
import { useGetChatQuery, useToggleChatMutation } from '../../store/chatSlice'
import { MessageHeader } from './MessageHeader'
import ProfileMsgBox from './ProfileMsgBox'
import ProfileTabs from './ProfileTab'
import axios from 'axios'
import { getTokenFromLocalStroage } from '../../utils'
import { useChatValues } from '../../contexts/chatValue'


export function MessageDrawer({
	state,
	toggleDrawer,
	name,
	id: pid,
	user,
	path,
	patient,
	acuity,
	userFeedback,
	originalMessage,
	chatbotReply,
	reasoning,
	createdAt,
	tabValue,
	queueStatus,
	taskCategory,
	setPage,
	page,
	title,
	queueId,
	refetchAgain,
	consent,
	refetchPatientAgain
}) {
	const {setChatMessages} = useChatValues()
	const [chatbot, setChatbot] = useState(false)
	const isMobile = useIsMobile()
	const { id: paraid } = useParams()
	const id = pid ? pid : paraid
	const [toggleChatbotPreference] = useToggleChatMutation()
	const { data, refetch, isLoading } = useGetChatQuery({ id, sid: '2', page })
	const isDrawer = true
	const [value, setValue] = useState(tabValue === 3 ? 2 : tabValue === 4 ? 0 : tabValue || 0)
	const [careAllyData, setCareAllyData] = useState(null)
	const config = {
		headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
	}

	const refetchChat = ()=>{
		refetch()
	}

	const fetchData = async () => {
		try {
			const response = await axios.get(`${process.env.REACT_APP_BASE_API_URL}/v2/careAlly/patient/${id}`, config)
			setCareAllyData(response.data)
		} catch (error) {
			setCareAllyData(null)
			console.error('Error fetching data:', error)
		}
	}

	const changeChatBotPreference = (preference) => {
		setChatbot(preference)
		toggleChatbotPreference({ id, chatBotEnabled: preference })
	}

	const refetchCareAllyAgain = () => {
		fetchData()
	}

	useEffect(() => {
		refetch()
		if (data && data.findNo && data.findNo.chatBotEnabled) {
			setChatbot(data.findNo.chatBotEnabled)
		}
	}, [data, refetch])

	useEffect(() => {
		fetchData()
	}, [id])

	useEffect(()=>{
		setChatMessages([])
	},[])

	return (
		<React.Fragment key={'anchor'}>
			<Drawer
				sx={{
					display: { sm: 'block' },
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						overflow: 'hidden',
						width: isMobile ? '90%' : '60%',
					},
				}}
				anchor="right"
				open={state}
				onClose={toggleDrawer(false)}
			>
				<Box
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: 'white',
						zIndex: 990,
					}}
				>
					<MessageHeader
						toggleDrawer={toggleDrawer}
						name={name}
						user={user}
						path={path}
						setChatbotValue={changeChatBotPreference}
						chatbotValue={chatbot}
						patient={patient}
						acuity={acuity}
						title={title}
						value={value}
						careAllyData={careAllyData?.careAlly ? careAllyData?.careAlly : null}
						id={id}
						refetchChat={refetchChat}
						status={patient?.status}
						consent={patient?.consentResponse}
					/>
				</Box>
				<Box sx={{ padding: '10px' }}>
					<ProfileTabs
						isDrawer={isDrawer}
						refetchAgain={refetchAgain}
						queueId={queueId}
						userFeedback={userFeedback}
						originalMessage={originalMessage}
						chatbotReply={chatbotReply}
						patient={patient}
						reasoning={reasoning}
						createdAt={createdAt}
						queueStatus={queueStatus}
						title={title}
						patientDrawer={true}
						consent={consent}
						acuity={acuity}
						tabValue={tabValue}
						taskCategory={taskCategory}
						value={value}
						setValue={setValue}
						careAllyData={careAllyData?.careAlly ? careAllyData?.careAlly : null}
						refetchCareAllyAgain={refetchCareAllyAgain}
						page={page}
						setPage={setPage}
						status={patient?.status}
						refetchPatientAgain={refetchPatientAgain}
						refetchChat={refetchChat}
					/>
				</Box>
			</Drawer>
		</React.Fragment>
	)
}
