import React, { useEffect, useState } from 'react'
import { useIsAuthenticatedUser } from '../../contexts/isAuthenticatedUser'
import { useGetChatQuery, useMessageSeenMutation } from '../../store/chatSlice'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { MenuItem, Alert, Typography, IconButton, Dialog, DialogContent, DialogContentText, DialogTitle, Menu, Tooltip, Badge } from '@mui/material'
import { MoreVert as MoreVertIcon, Close as CloseIcon } from '@mui/icons-material'
import { toast } from 'react-toastify'
import { getTokenFromLocalStroage } from '../../utils'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DoneIcon from '@mui/icons-material/Done';
import { MessageDrawer } from '../../Components/MessageLayout/MessageDrawer'
import { DetailPageDrawer } from '../Patient/Profile/DetailPageDrawer'
import { MessageSvg } from '../../assets/svgs'
import {useGetPatientsSurveyResultByIDQuery} from "../../store/patientsSurveySlice"
import { useChatValues } from '../../contexts/chatValue'
export function LastCell({
	id,
	firstName,
	path,
	queueStatus,
	patientId,
	onRefetch,
	patient,
	acuity,
	userFeedback,
	originalMessage,
	chatbotReply,
	reasoning,
	createdAt,
	taskCategory,value,FormData,
	refetchCount,
	formId
}) {
	const textColor = { color: '#979797' }
	const [currentReasoning, setCurrentReasoning] = useState('')
	const [currentChatbotReply, setCurrentChatbotReply] = useState('')
	const [messageCount, setMessageCount] = useState(0)
	const [itemStatus, setItemStatus] = useState(queueStatus)
	const [chatData, setChatData] = useState([])
	const [currentPatientMessage, setCurrentPatientMessage] = useState('')
	const [anchorEl, setAnchorEl] = React.useState(null)
	const [openModal, setOpenModal] = useState(false)
	const {setChatMessages} = useChatValues()
	const navigate = useNavigate()
	const config = {
		headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
	}

	const {data: surveyData} = useGetPatientsSurveyResultByIDQuery(formId)

	const open = Boolean(anchorEl)
	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = (event) => {
		setAnchorEl(null)
		if (event === undefined) {
			return
		}
	}
	const[page,setPage]=useState(1)
	const { data, refetch ,isLoading} = useGetChatQuery({ id: patientId, sid: '2',page })
	const [messageSeen] = useMessageSeenMutation()

	const { user } = useIsAuthenticatedUser()

	const [openChat, setOpenChat] = React.useState(false)

	const redirectToEditPage = (id) => {
		navigate(`/profile/${id}`, { state: { action: 'read-only', id } })
	}




	useEffect(() => {
		if (data) {
			setChatData(data?.data)

			const trueStatus = data?.data?.filter((obj) => obj.status === true && obj?.sentBy === 'user')
			const count = trueStatus?.length
			setMessageCount(count)
		}
	})

	const updateStatus = async (itemId, currentStatus) => {
		handleClose()
		const newStatus = currentStatus === 'pending' ? 'resolved' : 'pending'
		try {
			await axios.put(`${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatus/${itemId}`, { status: newStatus }, config).then((res) => {
				const status = res.data.status
				toast.success('The issue is ' + status)
				setItemStatus(status)
				onRefetch()
				refetchCount()
			})
		} catch (error) {
			toast.error('Error:', error)
		}
	}

	const showReasoningModal = async (itemId) => {
		handleClose()
		try {
			await axios.get(`${process.env.REACT_APP_BASE_API_URL}/patientQueues/fetchReasoning/${itemId}`, config).then((res) => {
				setCurrentReasoning(res.data.reasoning)
				setCurrentPatientMessage(res.data.originalMessage)
				setCurrentChatbotReply(res.data.chatbotReply)
				setOpenModal(true)
			})
		} catch (error) {
			toast.error('Error:' + error)
		}
		refetch()
	}

	const toggleDrawer = (open) => (event) => {
		handleClose()

		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return
		}

		setOpenChat(open)
		setChatMessages([])

		if (open) {
			messageSeen(patientId).then(() => {
				refetch()
			})
			setPage(1)
		}
	}

	const handleCloseModal = (event) => {
		setOpenModal(false)
	}
const [detail,setDetail]=useState(false)
	const toggleDetailDrawer = (detail) => (event) => {
		if (
		  event.type === "keydown" &&
		  (event.key === "Tab" || event.key === "Shift")
		) {
		  return;
		}
		setDetail(detail);
	  };



	return (
		<>
			{openModal && (
				<Dialog open={openModal} onClose={handleCloseModal}>
					<DialogTitle className="alignTextCenter" variant="h6">
						Reasoning
					</DialogTitle>
					<DialogContent>
						<Typography>
							<strong>Patient Message</strong>
						</Typography>
						<Alert severity="info">{currentPatientMessage}</Alert>
						<br />
						<Typography>
							<strong>Chatbot Reply</strong>
						</Typography>
						<Alert severity="success">{currentChatbotReply}</Alert>
						<br />
						<Typography>
							<strong>Prediction Reasoning</strong>
						</Typography>
						{currentReasoning.map((value) => (
							<>
								<Alert severity="warning">{value}</Alert>
								<br />
							</>
						))}
						<DialogContentText>
							<IconButton onClick={handleCloseModal} style={{ position: 'absolute', top: '8px', right: '10px' }}>
								<CloseIcon />
							</IconButton>
						</DialogContentText>
					</DialogContent>
				</Dialog>
			)}
			<div style={{ display: 'flex' }}>
				{value === 2 ?
				<IconButton
					sx={{
						'&: hover .css-tksnbz-MuiSvgIcon-root ': {
							visibility: 'visible',
						},
						padding: '0px',
						marginRight:'5px'
					}}
					onClick={(e)=>toggleDetailDrawer(true)(e)}
				>
					<Tooltip title="View Form">
						<VisibilityOutlinedIcon
						sx={{ fontSize: 26 }}
						// sx={{
						//   visibility: "hidden",
						// }}
						/>
					</Tooltip>
				</IconButton>:
				<IconButton
				sx={{
				  
				  padding: '0px',
				  marginRight: '5px',
				}}
				onClick={toggleDrawer(true)}
			  >
				<Tooltip title="View Chat">
					<Badge>
					<MessageSvg sx={{ fontSize: 26 }} />

					</Badge>
				</Tooltip>
			  </IconButton>
			  }
				
				<IconButton
					sx={{
						'&: hover .css-tksnbz-MuiSvgIcon-root ': {
							visibility: 'visible',
						},
						padding: '0px',
					}}
					onClick={() => updateStatus(id, queueStatus)}
				>
					{queueStatus === 'resolved' ? (
						<Tooltip title="Mark as Pending">
							<PendingActionsIcon sx={{ fontSize: 26 }}
							/>
						</Tooltip>
					) : (
						<Tooltip title="Mark as Resolved">
							<DoneIcon sx={{ fontSize: 26 }}
							/>
						</Tooltip>
					)}
				</IconButton>
			</div>

			<MessageDrawer
				toggleDrawer={toggleDrawer}
				state={openChat}
				id={patientId}
				name={firstName}
				user={user}
				queueStatus={queueStatus}
				path={path}
				acuity={acuity}
				patient={patient}
				userFeedback={userFeedback}
				originalMessage={originalMessage}
				chatbotReply={chatbotReply}
				reasoning={reasoning}
				createdAt={createdAt}
				taskCategory={taskCategory}
				queueId={id}
				tabValue={value}
				refetchAgain={onRefetch}
				consent={patient?.consentResponse}
				setPage={setPage}
				page={page}
				refetchPatientAgain={onRefetch}
			/>
			<DetailPageDrawer
        toggleDrawer={toggleDetailDrawer}
        state={detail}
        pendingurl={
          `${process.env.REACT_APP_FRONTEND_URI}/patientSurveys/` +
          surveyData?.data?.formId?._id +
          "/" +
		  surveyData?.data?._id
        }
      />
		</>
	)
}
