import React from "react";

export function TotalJourneysSvg() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#0085FF" />
      <path
        d="M46.1492 13H18.8508C16.1757 13 14 15.1764 14 17.8521V45.1473C14 47.823 16.1763 50 18.8508 50H46.1492C48.8243 50 51 47.8236 51 45.1473V17.8521C51 15.177 48.8243 13 46.1492 13ZM32.4842 17.2584C34.1218 17.2584 36.8039 17.9471 38.2997 19.5813L36.2864 23.4452C35.8383 22.8632 35.2624 22.3921 34.6032 22.0682C33.944 21.7443 33.2192 21.5763 32.4848 21.5771C31.7587 21.5763 31.042 21.7406 30.3889 22.0576C29.7357 22.3746 29.1633 22.8361 28.7148 23.4071L26.6622 19.6674C28.3906 17.9395 30.7756 17.2584 32.4842 17.2584ZM36.1739 26.2288C36.1731 27.2083 35.7833 28.1473 35.0903 28.8395C34.3973 29.5316 33.4578 29.9202 32.4783 29.9197C31.4994 29.9197 30.5607 29.5309 29.8685 28.8387C29.1763 28.1465 28.7875 27.2077 28.7875 26.2288C28.7875 25.2499 29.1763 24.3111 29.8685 23.6189C30.5607 22.9267 31.4994 22.5378 32.4783 22.5378C33.4578 22.5373 34.3973 22.9259 35.0903 23.6181C35.7833 24.3102 36.1731 25.2493 36.1739 26.2288ZM25.2906 34.1693C25.6259 32.9976 27.107 30.9197 29.669 30.9074L35.4271 30.9127C37.9317 30.9742 39.3829 33.0117 39.7141 34.1699L41.8329 41.1814H38.7282L37.376 36.5538H36.43L37.774 41.1814H27.2237L28.5735 36.5538H27.6281L26.2788 41.1814H23.1301L25.2906 34.1693ZM46.2899 45.2844H18.672V42.6567H46.2893V45.2844H46.2899Z"
        fill="white"
      />
    </svg>
  );
}
