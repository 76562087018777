import React, { useState } from 'react'
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined'
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Divider, Grid, IconButton, Typography } from '@mui/material'
import OutlinedButton from '../OutlinedButton'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined'
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined'
import SnackbarMsg from '../SnackbarMsg'
import WhatwentwrongDialog from '../../Pages/patientQueue/Profile/WhatwentwrongDialog'
import axios from 'axios'
import { toast } from 'react-toastify'
import { getTokenFromLocalStroage } from '../../utils'
import { useSelector } from 'react-redux'
import moment from 'moment'

export default function InterventionTab({
	refetchAgain,
	queueStatus,
	isDrawer,
	queueId,
	originalMessage,
	chatbotReply,
	patient,
	reasoning,
	createdAt,
	userFeedback,
	patientDrawer,
	acuity,
	taskCategory
}) {
	const [expanded, setExpanded] = useState(false)
	const { user } = useSelector((state) => state.user)
	const [open, setOpen] = useState(false)
	const [chatbotResponseCritique, setChatbotResponseCritique] = useState('')
	const [userFeedback2, setUserFeedback2] = useState([])
	const [selectedAcuity, setSelectedAcuity] = useState('')
	const [proposedChatbotResponse, setProposedChatbotResponse] = useState('')
	const [selectedTaskLabel, setSelectedTaskLabel] = useState('')
	const [currentItemId, setCurrentItemId] = useState('')
	const [openDislikeModal, setOpenDislikeModal] = useState(false)
	const [isTaskCategorySelected, setIsTaskCategorySelected] = useState(true)
	const [isChatbotResponse, setIsChatbotResponse] = useState(true)
	const [isAcuitySelected, setIsAcuitySelected] = useState(true)
	const [itemStatus, setItemStatus] = useState(queueStatus)
	const [chatbotResponse, setChatbotResponse] = useState(null)


	const handleExpand = () => {
		setExpanded(!expanded)
	}
	const updateStatus = async () => {
		const newStatus = itemStatus === 'pending' ? 'resolved' : 'pending'

		await axios
			.put(`${process.env.REACT_APP_BASE_API_URL}/patientQueues/updateStatus/${queueId}`, { status: newStatus }, config)
			.then((res) => {
				const status = res.data.status
				toast.success('The issue is ' + status)
				setItemStatus(status)
				if (refetchAgain) {
					refetchAgain()
				}
			})
			.catch((error) => {
				toast.error('Error:', error)
			})
	}
	const handleNotify = () => {}

	const addReaction = async (queueID, status) => {
		if (status === 'dislike') {
			if (!selectedAcuity || !selectedTaskLabel || !chatbotResponseCritique) {
				toast.error('Please fill out the form in-order to submit the feedback')
				return
			}
		}

		try {
			await axios
				.put(
					`${process.env.REACT_APP_BASE_API_URL}/patientQueues/addReaction/${queueID}`,
					{
						status: status,
						userId: user.id,
						correctTaskLabel: selectedTaskLabel,
						correctAcuityLabel: selectedAcuity,
						proposedChatbotResponse: proposedChatbotResponse,
						chatbotResponseCritique: chatbotResponseCritique,
					},
					config
				)
				.then((res) => {
					setOpen(true)
					// if(patientDrawer){
					// 	setUserFeedback2([res?.data?.feedback])
					// }
				})
		} catch (error) {
			toast.error('Error:' + error)
		}
		setCurrentItemId('')
		setSelectedTaskLabel('')
		setSelectedAcuity('')
		setProposedChatbotResponse('')
		setChatbotResponseCritique('')
		setIsAcuitySelected(false)
		setIsChatbotResponse(false)
		setIsTaskCategorySelected(false)
		setOpenDislikeModal(false)
		if (refetchAgain) {
			refetchAgain()
		}
	}

	const config = {
		headers: { Authorization: `Bearer ${getTokenFromLocalStroage()}` },
	}
	const setCurrentItem = async (itemId) => {
		setOpenDislikeModal(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	function renderFeedbackIcons(itemId, userFeedbackData) {
		const iconSkeleton = {
			color: '#979797',
			cursor: 'pointer',
		}

		const dislikeIcon = {
			cursor: 'pointer',
			color: 'red',
		}

		const feedbackIcons = {
			like: <EmojiEmotionsOutlinedIcon fontSize="medium" style={{ color: 'green' }} />,
			dislike: <SentimentDissatisfiedOutlinedIcon fontSize="medium" style={dislikeIcon} onClick={() => setCurrentItem(itemId)} />,
		}

		return (
			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start' }}>
				<>
					{userFeedbackData?.some((item) => item?.status === 'like') && feedbackIcons.like}
					{!userFeedbackData?.some((item) => item?.status === 'like') && (
						<EmojiEmotionsOutlinedIcon fontSize="medium" onClick={() => addReaction(queueId, 'like')} style={iconSkeleton} />
					)}
					&ensp;
				</>
				{userFeedbackData?.some((item) => item?.status === 'dislike') && feedbackIcons.dislike}
				{!userFeedbackData?.some((item) => item?.status === 'dislike') && (
					<SentimentDissatisfiedOutlinedIcon fontSize="medium" onClick={() => setCurrentItem(itemId)} style={iconSkeleton} />
				)}
			</div>
		)
	}

	React.useEffect(() => {

		setProposedChatbotResponse(userFeedback?.proposedChatbotResponse ? userFeedback?.proposedChatbotResponse : userFeedback?.chatbotReply)
		setChatbotResponse(chatbotReply)
		setChatbotResponseCritique(userFeedback?.chatbotResponseCritique)
		setSelectedTaskLabel(taskCategory)
		setSelectedAcuity(acuity)

		setCurrentItemId(queueId)
	}, [userFeedback, queueId])

	return (
		<>
			<div
				style={{
					overflowY: 'scroll',
					paddingBottom: isDrawer ? '50px' : '100px',
					height: isDrawer ? '70vh' : '60vh',
				}}
				className="DialogContent"
			>
				<Typography sx={{ fontSize: '16px', fontWeight: 600, mb: 1 }}>Issue</Typography>
				<Accordion expanded={expanded} sx={{ bgcolor: '#F7F8FB' }}>
					<AccordionSummary
						expandIcon={<ArrowDropDownCircleOutlinedIcon />}
						aria-controls="panel1-content"
						id="panel1-header"
						sx={{ flexDirection: 'row-reverse', borderRadius: '10px' }}
						onClick={handleExpand}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								width: '100%',
							}}
							onClick={(event) => event.stopPropagation()}
						>
							<div>
								<Typography
									sx={{
										fontSize: '14px',
										fontWeight: 600,
										ml: 1,
										width: '100%',
									}}
								>
									{patient?.patientSummary || 'Patient Summary is not available'}
								</Typography>

								<Typography
									sx={{
										fontSize: '14px',
										fontWeight: 500,
										ml: 1,
										color: '#979797',
										paddingTop: '5px',
									}}
								>
									Added by: Chatbot on {moment(createdAt)?.format('MM DD, YYYY')}
								</Typography>
							</div>
							<div>{renderFeedbackIcons('id', userFeedback)}</div>
						</div>
					</AccordionSummary>

					<AccordionDetails>
						<Grid container spacing={2}>
							<Grid item md={3.9} padding={'10px'}>
								<Typography sx={{ fontSize: '12px', color: '#979797' }}>Patient Message </Typography>
								<Typography sx={{ fontSize: '14px' }}>{originalMessage}</Typography>
							</Grid>
							<Divider orientation="vertical" flexItem />
							<Grid item md={3.9} padding={'10px'}>
								<Typography sx={{ fontSize: '12px', color: '#979797' }}>Chatbot Reply</Typography>
								<Typography sx={{ fontSize: '14px' }}>{chatbotReply}</Typography>
							</Grid>
							<Divider orientation="vertical" flexItem />
							<Grid item md={4} padding={'10px'}>
								<Typography sx={{ fontSize: '12px', color: '#979797' }}>Prediction Reasoning </Typography>
								{/* <Typography sx={{ fontSize: "14px" }}> */}
								<ul>
									{reasoning?.map((item, index) => (
										<li style={{ fontSize: '14px' }} key={index}>
											{item}
										</li>
									))}
								</ul>
								{/* </Typography> */}
							</Grid>
						</Grid>
					</AccordionDetails>
					<AccordionActions>
						<Box sx={{ padding: '12px' }}>
							<OutlinedButton label={itemStatus === 'resolved' ? 'Mark as Pending' : 'Mark as Resolved'} handleClick={() => updateStatus()} />
						</Box>
						{/* <OutlinedButton label={'Notify Team Member'} onClick={handleNotify} /> */}
					</AccordionActions>
				</Accordion>
			</div>
			<SnackbarMsg msg={'Thank you for giving us feedback!'} handleClose={handleClose} open={open} width={'310px'} />
			<WhatwentwrongDialog
				open={openDislikeModal}
				setOpen={setOpenDislikeModal}
				addReaction={addReaction}
				queueId={queueId}
				originalMessage={originalMessage}
				isAcuitySelected={isAcuitySelected}
				setIsAcuitySelected={setIsAcuitySelected}
				isChatbotResponse={isChatbotResponse}
				setIsChatbotResponse={setIsChatbotResponse}
				isTaskCategorySelected={isTaskCategorySelected}
				setIsTaskCategorySelected={setIsTaskCategorySelected}
				proposedChatbotResponse={proposedChatbotResponse}
				setProposedChatbotResponse={setProposedChatbotResponse}
				chatbotResponseCritique={chatbotResponseCritique}
				setChatbotResponseCritique={setChatbotResponseCritique}
				selectedAcuity={selectedAcuity}
				setSelectedAcuity={setSelectedAcuity}
				selectedTaskLabel={selectedTaskLabel}
				setSelectedTaskLabel={setSelectedTaskLabel}
				chatbotResponse={chatbotResponse}
			/>
		</>
	)
}
