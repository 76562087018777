import React, { useState } from "react";
import Button from "@mui/material/Button";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { Box, Divider } from "@mui/material";
import SnackbarMsg from "../SnackbarMsg";
import { useIsMobile } from "../../contexts/isMobile";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import AddIcon from "@mui/icons-material/Add";
import AddAiAssistantModal from "./AddAiAssistantModal";
import { useGetAgentsQuery } from "../../store/agentsSlice";
import { useGetAssistantsQuery } from "../../store/assistantsSlice";
import { useAssistantChatValues } from "../../contexts/assistantChatValue";

export const chatbotMode = [
  {
    title: "Item 1",
    value: "item-1",
    text: "Here will be description.",
  },
  {
    title: "Item 2",
    value: "item-2",
    text: "Here will be long description because Edit and approve Chatbot messages before sending.",
  },
  {
    title: "Item 3",
    value: "item-3",
    text: "Description will be here.",
  },
];

function AiAssistantDropdownButton({
  selectedValue,
  setSelectedValue,
  pathname,
  setAgentId,
  isAIAssistant,
  isAIAssistantEdited, 
  setIsAIAssistantEdited,
  title
}) {
  const {setAssistantChatMessages} = useAssistantChatValues()
  const [anchorEl, setAnchorEl] = useState(null);
  const [openBar, setOpenBar] = useState(false);
  const [addAiModal, setAddAiModal] = useState(false);
  const [chatbotModeOptions, setChatbotModeOptions] = useState([]);
  const [editData, setEditData] = useState(null);
  const isMobile = useIsMobile();

  const { data, refetch } = useGetAgentsQuery();
  const { data: assistantData, refetch: assistantRefetch } =
    useGetAssistantsQuery();

  const handleModal = (itemData) => {
    setAddAiModal(true);
    if (itemData) {
      setEditData(itemData);
    }
  };

  const refetchAgain = () => {
    if (isAIAssistant) {
      assistantRefetch();
    } else {
      refetch();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const msg = selectedValue && `${selectedValue} Mode Activated`;
  const msg2 =
    selectedValue === "Care-Guard"
      ? "Chatbot is now in care-guard mode. Review and confirm messages before they are sent."
      : selectedValue === "Manual"
      ? "You are in control. Manual mode enabled for direct patient messaging."
      : selectedValue === "Autopilot"
      ? "Chatbot is autonomously managing the conversation with the patient."
      : "";

  const handleBar = () => {
    setOpenBar(true);
    setEditData(null);
  };
  const handleBarClose = () => {
    setOpenBar(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (itemValue) => {
    // const isTrue = itemValue?.value === 'manual' ? false : true;
    setSelectedValue(itemValue?.title);
    setAgentId(itemValue?._id);
    // toggleChatbotPreference({ id: patientId, chatBotEnabled: isTrue });

    if(isAIAssistant){
      setAssistantChatMessages([])
    }

    handleClose();
  };

  function truncateString(str, maxLength) {
  if (str.length <= maxLength) {
    return str;
  }
  return str.substring(0, maxLength) + '..';
}

  React.useEffect(() => {
    setChatbotModeOptions(chatbotMode);
  }, []);

  React.useEffect(()=>{
    if(isAIAssistant && isAIAssistantEdited){
      refetchAgain()
      setIsAIAssistantEdited(false)
      setSelectedValue(title)

    }
  },[isAIAssistantEdited])

  return (
    <>
      <Button
        variant="outlined"
        size="medium"
        sx={{
          borderColor: "#80A4E3",
          color: "black",
          borderRadius: "30px",
          textTransform: "capitalize",
          backgroundColor: "white",
          fontWeight: 600,
          p:
            (pathname === "dashboard" || pathname === "queue") &&
           ( isAIAssistant ? selectedValue === "Available Assistants" : selectedValue === "Available Agents")
              ? "8px 11px"
              : "8px 20px",
          mt: isMobile && 1,
        }}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon sx={{ marginLeft: "8px" }} />}
      >
        {selectedValue ? truncateString(selectedValue, 15) : isAIAssistant ? "Available Assistants" : "Available Agents"}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            maxHeight: "300px",
            overflowY: "auto",
          }}
          className="ScrollDesign"
        >
          {isAIAssistant && assistantData && assistantData?.length > 0 ? (
            assistantData?.map((item) => (
              <>
                <Typography
                  key={item.title}
                  sx={{
                    py: "5px",
                    px: "15px",
                    cursor: "pointer",
                    color: "black",
                    width: "300px",
                  }}
                  onClick={() => handleSelect(item)}
                >
                  <div>{item.title}</div>
                  <Typography sx={{ cursor: "pointer", color: "#979797" }}>
                    {item.prompt?.length > 60
                      ? `${item.prompt.substring(0, 60)}...`
                      : item.prompt}
                  </Typography>
                </Typography>
                <Divider sx={{ width: "100%", py: 1 }} />
              </>
            ))
          ) : !isAIAssistant && data && data.length > 0 ? (
            data?.map((item) => (
              <>
                <Typography
                  key={item.title}
                  sx={{
                    py: "5px",
                    px: "15px",
                    cursor: "pointer",
                    color: "black",
                    width: "300px",
                  }}
                  onClick={() => handleSelect(item)}
                >
                  {!isAIAssistant ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        lineHeight: "0px",
                      }}
                    >
                      {item.title}
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleModal(item);
                        }}
                      >
                        <ModeEditIcon
                          sx={{
                            color: "gray",
                            margin: "5px",
                            fontSize: "18px",
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div>{item.title}</div>
                  )}

                  <Typography sx={{ cursor: "pointer", color: "#979797" }}>
                    {item.description?.length > 60
                      ? `${item.description.substring(0, 60)}...`
                      : item.description}
                  </Typography>
                </Typography>
                <Divider sx={{ width: "100%", py: 1 }} />
              </>
            ))
          ) : (
            <>
              <Typography
                sx={{
                  pt: "20px",
                  color: "#979797",
                  width: "300px",
                  textAlign: "center",
                }}
              >
                No Assistant Available!
              </Typography>
              <Divider sx={{ width: "100%", py: 1 }} />
            </>
          )}
        </Box>
        <div
          style={{ textAlign: "end", marginTop: "10px", marginRight: "10px" }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{
              background: "#0049C6",
              borderRadius: "30px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
            onClick={() => handleModal()}
            startIcon={<AddIcon />}
          >
           { isAIAssistant ? 'Add Assistant' : 'Add Agent'}
          </Button>
        </div>
        <Divider sx={{ width: "90%", p: 1 }} />
      </Popover>

      <SnackbarMsg
        msg={msg}
        msg2={msg2}
        open={openBar}
        handleClose={handleBarClose}
        width={"390px"}
      />

      <AddAiAssistantModal
        {...{
          open: addAiModal,
          setOpen: setAddAiModal,
          editData,
          setEditData,
          refetchAgain,
		  isAIAssistant
        }}
      />
    </>
  );
}

export default AiAssistantDropdownButton;
