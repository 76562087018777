import React from "react";
import { PatientTask } from "./index";
import { AttentionAndForm } from "./AttentionAndForm";
import { TotalCount } from "./TotalCount";
import { PatientCount } from "./PatientCount";
import { CircularLoader, InactivityCheck } from "../../Components";
import { CareCount } from "./CareBoardCount";
import { PatientQueue } from "./PatientQueue";
import { useSelector } from "react-redux";
import {
  useGetTotalCountQuery,
  useGetProgramCountQuery,
} from "../../store/dashboardsSlice";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useIsMobile } from "../../contexts/isMobile";

export function Dashboard() {
  const isMobile = useIsMobile();

  // Extracting the user from state
  const { user } = useSelector((state) => state.user);

  // Integration of total Count api
  const { data, isLoading, isError } = useGetTotalCountQuery(user.id);

  // Integration of Porgram Count Query api
  const {
    data: programData,
    isLoading: programDataLoading,
    isError: programDataError,
  } = useGetProgramCountQuery(user.id);

  // Loader for both data
  if (isLoading && programDataLoading)
    return (
      <>
        <CircularLoader />
      </>
    );

  // If Token is expire it navigate to Login page
  // !isAuthenticated && navigate("/")
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: isMobile ? "1.5rem 1rem" : "1.5rem 2rem",
      }}
    >
      <TotalCount {...{ data: data || [], isError }} />
      <PatientQueue />
      <InactivityCheck />
    </div>
  );
}
