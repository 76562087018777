import React from "react";
import { Chip, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export function PatientInfo({ patient, id, redirectUrl, surgeryDate, pageName }) {
  const navigate = useNavigate();

  const redirectToEditPage = (id, name) => {
    if(name){
      localStorage.setItem('pageName', JSON.stringify(name))
    }
    navigate(`/${redirectUrl}/${id}`, { state: { action: "read-only", id } });
  };

  const calculateAge = (dob) => {
    if(dob){
      // Check if the date is in ISO 8601 format
      let birthDate = dob.includes('T') ? new Date(dob) : new Date(`${dob}T00:00:00.000Z`);
      let today = new Date();
      let age_now = today.getFullYear() - birthDate.getFullYear();
      let m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return `${age_now}Y`;
    } else {
      return `N/A`;
    }
  };

  const getCarePhase = (surgeryDate) => {
    if (new Date(surgeryDate) > new Date()) {
      return "Post-op";
    } else if (new Date(surgeryDate) < new Date()) {
      return "Pre-op";
    }
  };

  return (
    <>
      <Typography
        variant="p"
        sx={{
          fontSize: 16,
          fontWeight: 400,
          textAlign: "left",
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography
            onClick={() => redirectToEditPage(id, pageName)}
            variant="body2"
            style={{
              textDecoration: "underline",
              cursor: "pointer",
              paddingRight: "5px",
              fontWeight: 600,
              color: "#000000",
              "&:hover": {
                color: "#816179",
              },
            }}
          >
            {patient.firstName}
            {" "} {patient.lastName}
          </Typography>
          {surgeryDate != null && (
            <Chip label={getCarePhase(surgeryDate)} size="small" />
          )}
        </div>
          {calculateAge(patient.birthday)},{" "}
          {patient.gender?.charAt(0)?.toUpperCase() || 'N/A'}
         
      </Typography>
      <Typography sx={{  color: "#979797" }}>
        <span style={{color:"#979797",fontWeight:500}}>DOB:</span> {patient.birthday? moment(patient?.birthday).format("MM-DD-YYYY") :"N/A"}
          </Typography>
    </>
  );
}