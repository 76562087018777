import React from "react";
import { Box, Typography } from "@mui/material";
import { TotallPatientSvg } from "../../../assets/svgs";
import { useIsMobile } from "../../../contexts/isMobile";

export function TotalPatient({ data }) {
  const isMobile = useIsMobile();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: !isMobile && "center",
          alignItems: "center",
          margin: !isMobile ? "10px 0px" : "10px 10px",
        }}
      >
        <Box sx={{ margin: "10px" }}>
          <TotallPatientSvg />
        </Box>
        <Box sx={{ margin: "5px" }}>
          <Typography sx={{ color: "#979797", fontSize: "18px" }}>
            Total Patient
          </Typography>
          <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
            {data}
          </Typography>
        </Box>
      </Box>
    </>
  );
}
