import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Box from '@mui/material/Box';
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export const TimeFrames = [
  {
    title: "All",
    value: "all",
  },
  {
    title: "Today",
    value: "today",
  },
  {
    title: "Yesterday",
    value: "yesterday",
  },
  {
    title: "Last 7 Days",
    value: "7",
  },
  {
    title: "Last 30 Days",
    value: "30",
  },
];

export function TimeFrameDropdown({ selectedValue, setSelectedValue }) {

    const handleChange = (event) => {
        localStorage.setItem('selectedValue', event.target.value)
        setSelectedValue(event.target.value);
    };

  return (
    <Box>
    <FormControl  sx={{width:"200px"}} >
      <InputLabel  id="demo-simple-select-label">Select Time Frame</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        label="Select Time Frame"
        onChange={handleChange}
        sx={{textAlign:"left"}}
      >

      {TimeFrames.map(values => 
        <MenuItem key={values._id} value={values.value}>{values.title}</MenuItem>
       )}

      </Select>
    </FormControl>
  </Box>
  );
}
