import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import { useState } from 'react'
import { useIsMobile } from '../../contexts/isMobile'
import CollectionsOutlinedIcon from '@mui/icons-material/CollectionsOutlined'
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined'
import { QueueMsgs } from './QueueMsgs'
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined'
import { Badge, Tooltip } from '@mui/material'
import { CareAllyColumn, FormsColumn, PhotosColumn, dialoguesColumn, messagesColumn } from './QueueUtils'
import { useGetPatientQueueBadgeCountQuery } from '../../store/patientQueuesSlice'
import { useEffect } from 'react'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'

function CustomTabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && (
				<Box>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

CustomTabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

export default function QueueTabs() {
	const isMobile = useIsMobile()
	const [value, setValue] = useState(localStorage.getItem('value') ? parseInt(localStorage.getItem('value')) : 0)

	const { data, isLoading, refetch } = useGetPatientQueueBadgeCountQuery()

	const handleChange = (event, newValue) => {
		localStorage.setItem('value', newValue)
		setValue(newValue)
	}

	const refetchCount = () => {
		refetch()
	}

	useEffect(() => {
		if (data) {
			const interval = setInterval(() => {
				refetch()
			}, 5000)

			return () => clearInterval(interval)
		}
	}, [data])

	if (isLoading) return <>{console.log('Loading...')}</>

	const tabItems = []

	tabItems.push(
		<Tab
			key="messages"
			label={
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						fontSize: '12px',
						fontWeight: 600,
						textTransform: 'capitalize',
						color: value === 0 ? '#000' : 'rgba(0,0,0,0.60)',
						paddingRight: '20px',
					}}
				>
					<ChatBubbleOutlineIcon style={{ marginRight: '5px' }} />
					Messages
					{data?.patientQueueCount[0]?.messageCount !== 0 && (
						<Tooltip title="Pending Queues Count">
							<Badge badgeContent={data?.patientQueueCount[0]?.messageCount} color="primary" sx={{ ml: 2.5 }} />
						</Tooltip>
					)}
				</div>
			}
			{...a11yProps(0)}
		/>
	)

	tabItems.push(
		<Tab
			key="gallery"
			label={
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						textTransform: 'capitalize',
						fontSize: '12px',
						fontWeight: 600,
						color: value === 1 ? '#000' : 'rgba(0,0,0,0.60)',
					}}
				>
					<CollectionsOutlinedIcon style={{ marginRight: '5px' }} />
					Photo Uploads
					{data?.patientQueueCount[0]?.newMediaCount !== 0 && (
						<Tooltip title="Pending Queues Count">
							<Badge badgeContent={data?.patientQueueCount[0]?.newMediaCount} color="primary" sx={{ ml: 2.5 }} />
						</Tooltip>
					)}
				</div>
			}
			{...a11yProps(1)}
		/>
	)

	tabItems.push(
		<Tab
			key="forms"
			label={
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						textTransform: 'capitalize',
						fontSize: '12px',
						fontWeight: 600,
						color: value === 2 ? '#000' : 'rgba(0,0,0,0.60)',
					}}
				>
					<FeedOutlinedIcon style={{ marginRight: '5px' }} />
					Forms
					{data?.patientQueueCount[0]?.formSubmissionCount !== 0 && (
						<Tooltip title="Pending Queues Count">
							<Badge badgeContent={data?.patientQueueCount[0]?.formSubmissionCount} color="primary" sx={{ ml: 2.5 }} />
						</Tooltip>
					)}
				</div>
			}
			{...a11yProps(2)}
		/>
	)

	tabItems.push(
		<Tab
			key="care-ally"
			label={
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						textTransform: 'capitalize',
						fontSize: '12px',
						fontWeight: 600,
						color: value === 3 ? '#000' : 'rgba(0,0,0,0.60)',
					}}
				>
					<HandshakeOutlinedIcon style={{ marginRight: '5px' }} />
					Care Ally Messages
					{data?.patientQueueCount[0]?.careAllyMessageCount !== 0 && (
						<Tooltip title="Pending Queues Count">
							<Badge badgeContent={data?.patientQueueCount[0]?.careAllyMessageCount} color="primary" sx={{ ml: 2.5 }} />
						</Tooltip>
					)}
				</div>
			}
			{...a11yProps(3)}
		/>
	)

	tabItems.push(
		<Tab
			key="dialogue"
			label={
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						fontSize: '12px',
						fontWeight: 600,
						textTransform: 'capitalize',
						color: value === 4 ? '#000' : 'rgba(0,0,0,0.60)',
						paddingRight: '20px',
					}}
				>
					<ForumOutlinedIcon style={{ marginRight: '5px' }} />
					Dialogue Assessments
					{data?.patientQueueCount[0]?.dialoguesCount !== 0 && (
						<Tooltip title="Pending Dialogue Assessments Count">
							<Badge badgeContent={data?.patientQueueCount[0]?.dialoguesCount} color="primary" sx={{ ml: 2.5 }} />
						</Tooltip>
					)}
				</div>
			}
			{...a11yProps(4)}
		/>
	)

	return (
		<Box sx={{ width: '100%' }}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					flexDirection: isMobile ? 'column' : 'row',
					top: '5.8rem',
					height: '65%',
					zIndex: '990',
					padding: 3,
				}}
			>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
					TabIndicatorProps={{
						sx: {
							left: `calc(${value} * 15%)`,
							'&.MuiTabs-indicator': {
								backgroundColor: '#000',
							},
						},
					}}
				>
					{tabItems}
				</Tabs>
			</div>
			<CustomTabPanel value={value} index={0}>
				<QueueMsgs value={value} columns={messagesColumn} refetchCount={refetchCount} acuityCount={data?.patientQueueCount[0]?.queueWithHighAcuityCount} />
			</CustomTabPanel>

			<CustomTabPanel value={value} index={1}>
				<QueueMsgs value={value} columns={PhotosColumn} refetchCount={refetchCount} />
			</CustomTabPanel>

			<CustomTabPanel value={value} index={2}>
				<QueueMsgs value={value} columns={FormsColumn} refetchCount={refetchCount} />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={3}>
				<QueueMsgs value={value} columns={CareAllyColumn} refetchCount={refetchCount} />
			</CustomTabPanel>
			<CustomTabPanel value={value} index={4}>
				<QueueMsgs value={value} columns={dialoguesColumn} refetchCount={refetchCount} />
			</CustomTabPanel>
		</Box>
	)
}
