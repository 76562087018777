import React, { useState, useEffect } from 'react'
import {
    Paper,
    Typography,
} from "@mui/material";
import { useIsMobile } from '../../../contexts/isMobile'
import QueueTabs from "../../patientQueue/QueueTabs";

export function PatientQueue() {
    const isMobile=useIsMobile()
    return (
        <Paper
            sx={{
                width: "100%",
                boxShadow: "0px 0px 15px rgba(52, 58, 64, 0.2)",
                border: "1px solid #D0EAE1",
                mt: "3rem"

            }}
            elevation={0}
        >
            <Typography variant="h5" sx={{padding:'25px 0px 0px 30px'}}>Patient Queue</Typography>
            <div style={{ padding: isMobile ? '1.5rem 1rem' : '1.5rem 2rem' }}>
            <QueueTabs />
            </div>
        </Paper>
    );
}
