import React from "react";
import { Grid } from "@mui/material";
import { TotalPatient } from "./TotalPatient";
import { TotalJourneys } from "./TotalJourneys";
import { TotalForms } from "./TotalForms";
import { TotalTasks } from "./TotalTasks";

export function CountGrid({data, isError}) {
  // Mapping of keys to corresponding component imports
  const componentName = {
    patient: TotalPatient,
    form: TotalForms,
    journey: TotalJourneys,
    task: TotalTasks,
  };

  if (isError) <p>Some error</p>;

  return (
    <>
      <Grid container spacing={2}>
        {data?.map((item, index) => {
          // Extract the first key (property name) from the current object
          const key = Object.keys(item)[0];

          // Get the corresponding component based on the extracted key
          const Component = componentName[key];

          // Extract the value (data) associated with the key
          const countData = item[key];

          // Render the component with the extracted data
          return (
            <Grid key={index} item md={3} xs={12}>
              <Component data={countData} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
